// 智能排课
<template>
  <div class="smart_manage_course">
    <div v-if="courseType == 0 && loading" class="course_info">
      <div class="margin_top_10">排课日期：{{ CourseItem.beginTime != null ? CourseItem.beginTime + "至" + CourseItem.endTime : "" }}</div>
      <div class="margin_top_10">已排课次：{{ CourseItem.planNum }}</div>
      <div class="margin_top_10">已上课次：{{ CourseItem.onNum }}</div>
      <div class="margin_top_10">已取消课次：{{ CourseItem.cancelNum }}</div>
    </div>
    <div class="margin_left_30">
      重复方式
      <el-select class="margin_left_10" v-model="courseType" placeholder="请选择" size="small" @change="changeType">
        <el-option v-for="item in courseTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>

    <div class="margin_top_10">
      <span>排课开始日期 </span>
      <el-date-picker
        class="select_item margin_left_10"
        v-model="selectDate"
        type="date"
        size="small"
        placeholder="选择日期"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd"
        @change="changeDate"
      >
      </el-date-picker>

      <span class="margin_left_40">
        <el-checkbox v-model="skipHoliday" @change="changeDate">自动跳过节假日</el-checkbox>
      </span>
    </div>

    <div class="margin_left_30 margin_top_10">
      <!-- 排课课时 -->
      排课课次
      <el-input class="input_item margin_left_10" size="small" clearable v-model="courseTime" @input="handleEdit" placeholder="请输入课次"></el-input>
    </div>

    <!-- 间隔排课展示内容 -->
    <div v-if="courseType == 1" class="margin_left_30 margin_top_10">
      相隔天数
      <el-select class="select_item margin_left_10" v-model="day" placeholder="" size="small">
        <el-option v-for="item in days" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </div>

    <!-- 每周排课展示内容 -->
    <div v-if="courseType == 0">
      <span style="font-size: 18px">星期</span>
      <el-checkbox-group class="margin_top_20 margin_left_40 checkbox-group" v-model="checkWeek" @change="select">
        <el-checkbox label="星期一"></el-checkbox>
        <el-checkbox label="星期二"></el-checkbox>
        <el-checkbox label="星期三"></el-checkbox>
        <el-checkbox label="星期四"></el-checkbox>
        <el-checkbox label="星期五"></el-checkbox>
        <el-checkbox label="星期六"></el-checkbox>
        <el-checkbox label="星期日"></el-checkbox>
      </el-checkbox-group>
    </div>

    <!-- 每周排课 -->
    <div v-if="courseType == 0">
      <div class="Plan">
        <span class="PlanItem_first">星期</span>
        <span class="PlanItem_second">时段</span>
        <span class="PlanItem_third">教室</span>
        <span class="PlanItem">扣除课时数</span>
        <span class="PlanItem">操作</span>
      </div>

      <div :style="{ height: maxHeight }">
        <div class="PlanList">
          <div v-for="(PlanItem, index1) in plan.weekList" :key="index1">
            <div class="plan_action" v-for="(dayItem, index2) in PlanItem.dayPlan" :key="index2">
              <!-- 星期 v-show="index2 == 0" -->
              <span class="List_item List_item_first" :class="[index2 == 0 ? 'show' : 'hidden']"> {{ "星期" + weektext[PlanItem.weekDay] }}</span>

              <!-- 时段选择 -->
              <span :class="['List_item', index2 == 0 ? 'List_item_first' : 'List_item_second']">
                <el-select v-model="dayItem.time_id" placeholder="请选择时段" size="small">
                  <el-option v-for="item in timeList" :key="item.time_id" :label="item.time_range" :value="item.time_id"> </el-option>
                </el-select>
              </span>

              <span class="List_item">
                <!-- 教室选择 -->
                <el-select v-model="dayItem.room_id" placeholder="请选择教室" size="small">
                  <el-option v-for="item in roomList" :key="item.room_id" :label="item.room_name" :value="item.room_id"> </el-option>
                </el-select>
              </span>

              <!-- 扣除课时数 -->
              <span class="List_item">
                <el-select v-model="dayItem.deductionHours" placeholder="1" size="small">
                  <el-option v-for="item in numList" :key="item.deductionHours" :label="item.num_name" :value="item.deductionHours"> </el-option>
                </el-select>
              </span>

              <!-- 添加删除 -->
              <span class="List_item">
                <span class="botton_ctrl iconfont icon-tianjia1" @click="AddPlanItem(index1, index2, 0)"></span>
                <span class="botton_ctrl iconfont icon-shanchu" @click="DelPlanItem(index1, index2, 0)"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 隔天排课 -->
    <div v-if="courseType == 1">
      <div class="plan_day">
        <span class="PlanItem_second">时段</span>
        <span class="PlanItem">教室</span>
        <span class="PlanItem">扣除课时数</span>
        <span class="PlanItem">操作</span>
      </div>

      <div :style="{ height: maxHeight }">
        <div class="PlanList">
          <div v-for="(PlanItem, index1) in plan_interval.weekList" :key="index1">
            <div class="plan_action_day" v-for="(dayItem, index2) in PlanItem.dayPlan" :key="index2">
              <!-- 时段选择 -->
              <span :class="['List_item', index2 == 0 ? 'List_item_first' : 'List_item_first']">
                <el-select v-model="dayItem.time_id" placeholder="请选择时段" size="small">
                  <el-option v-for="item in timeList" :key="item.time_id" :label="item.time_range" :value="item.time_id"> </el-option>
                </el-select>
              </span>

              <!-- 教室选择 -->
              <span class="List_item">
                <el-select v-model="dayItem.room_id" placeholder="请选择教室" size="small">
                  <el-option v-for="item in roomList" :key="item.room_id" :label="item.room_name" :value="item.room_id"> </el-option>
                </el-select>
              </span>

              <!-- 扣除课时数 -->
              <span class="List_item">
                <el-select v-model="dayItem.deductionHours" placeholder="请选择扣除课时数" size="small">
                  <el-option v-for="item in numList" :key="item.deductionHours" :label="item.num_name" :value="item.deductionHours"> </el-option>
                </el-select>
              </span>

              <!-- 添加 删除 -->
              <span class="List_item">
                <span class="botton_ctrl iconfont icon-tianjia1" @click="AddPlanItem(index1, index2, 1)"></span>
                <span v-show="index2 != 0" class="botton_ctrl iconfont icon-shanchu" @click="DelPlanItem(index1, index2, 1)"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提交 -->
    <div class="bom_action">
      <!-- 取消 -->
      <div class="cancel" @click.stop="close"><span>返</span><span style="margin-left: 16px">回</span></div>
      <el-button
        :loading="showCommitLoading"
        :class="[showSaveDisabled ? 'commit_disable' : 'commit']"
        @click.stop="doCommitAction"
        :disabled="showSaveDisabled"
      >
        <span>保存并返回</span>
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      levelId_1: "",
      planType: "",
      //保存按钮是否隐藏
      showSaveDisabled: false,
      //json
      PlanJson: "",
      showCommitLoading: false,
      //每周排课计划集合
      plan: [],
      //隔天排课计划集合
      plan_interval: [],
      weektext: ["一", "二", "三", "四", "五", "六", "日"],
      loading: false,
      //时段数组
      timeList: [],
      //教室数组
      roomList: [],
      //课时数字
      numList: [
        {
          deductionHours: 1,
          num_name: "1"
        },
        {
          deductionHours: 2,
          num_name: "2"
        },
        {
          deductionHours: 3,
          num_name: "3"
        },
        {
          deductionHours: 4,
          num_name: "4"
        },
        {
          deductionHours: 5,
          num_name: "5"
        },
        {
          deductionHours: 6,
          num_name: "6"
        },
        {
          deductionHours: 7,
          num_name: "7"
        },
        {
          deductionHours: 8,
          num_name: "8"
        },
        {
          deductionHours: 9,
          num_name: "9"
        },
        {
          deductionHours: 10,
          num_name: "10"
        }
      ],
      //班级排课属性
      CourseItem: null,
      //星期几
      checkWeek: [],
      //跳过节假日
      skipHoliday: false,
      //课时数
      courseTime: "",
      //筛选日期
      selectDate: "",
      //排课方式
      courseTypes: [
        {
          value: 0,
          label: "每周排课"
        },
        {
          value: 1,
          label: "隔天排课"
        }
      ],
      //排课方式
      courseType: 0,

      //间隔天数
      days: [
        {
          value: 1,
          label: "1"
        },
        {
          value: 2,
          label: "2"
        },
        {
          value: 3,
          label: "3"
        },
        {
          value: 4,
          label: "4"
        },
        {
          value: 5,
          label: "5"
        },
        {
          value: 6,
          label: "6"
        },
        {
          value: 7,
          label: "7"
        }
      ],
      //间隔天数
      day: 0,
      maxHeight: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      }
    };
  },
  created() {
    //初始化数据
    this.initData();
  },
  mounted() {
    this.levelId_1 = this.levelId;
    this.httpData();
  },
  methods: {
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - 56 - 40 - 32 - 32 - 32 - 36 - 60 - 95 + "px";
      if (!this.isEmpty(this.plan.weekList)) {
        this.showSaveDisabled = false;
      }
    },

    changeDate() {
      if (this.isEmpty(this.courseId)) {
        this.showWarnMsg("请先选择课程及对应的课程级别");

        return;
      } else {
        if (this.isEmpty(this.levelId_1)) {
          this.showWarnMsg("请选择课程对应的级别");

          return;
        }
      }
    },

    //选择星期
    select(val) {
      if (this.roomList.length == 0) {
        this.showWarnMsg("请先在排课管理里设置上课教室");
        return;
      }
      if (this.timeList.length == 0) {
        this.showWarnMsg("请先在排课管理里设置上课时段");
        return;
      }

      if (this.isEmpty(this.courseId)) {
        this.showWarnMsg("请先选择课程及对应的课程级别");

        return;
      } else {
        if (this.isEmpty(this.levelId_1)) {
          this.showWarnMsg("请选择课程对应的级别");

          return;
        }
      }

      if (this.isEmpty(this.checkWeek)) {
        this.plan.weekList.splice(0, 1);
      }

      //删除星期未勾选的排课内容
      this.checkWeek.forEach(element => {
        var temp = true;

        if (element == "星期一") {
          temp = false;
        }

        if (temp) {
          this.plan.weekList.forEach((element3, index) => {
            if (element3.weekDay == 0) {
              this.plan.weekList.splice(index, 1);
            }
          });
        }
      });
      this.checkWeek.forEach(element => {
        var temp = true;

        if (element == "星期二") {
          temp = false;
        }
        console.log(temp);
        if (temp) {
          this.plan.weekList.forEach((element3, index) => {
            if (element3.weekDay == 1) {
              this.plan.weekList.splice(index, 1);
              console.log(this.plan.weekList);
            }
          });
        }
      });
      this.checkWeek.forEach(element => {
        var temp = true;

        if (element == "星期三") {
          temp = false;
        }
        console.log(temp);
        if (temp) {
          this.plan.weekList.forEach((element3, index) => {
            if (element3.weekDay == 2) {
              this.plan.weekList.splice(index, 1);
            }
          });
        }
      });
      this.checkWeek.forEach(element => {
        var temp = true;

        if (element == "星期四") {
          temp = false;
        }
        console.log(temp);
        if (temp) {
          this.plan.weekList.forEach((element3, index) => {
            if (element3.weekDay == 3) {
              this.plan.weekList.splice(index, 1);
            }
          });
        }
      });
      this.checkWeek.forEach(element => {
        var temp = true;

        if (element == "星期五") {
          temp = false;
        }
        console.log(temp);
        if (temp) {
          this.plan.weekList.forEach((element3, index) => {
            if (element3.weekDay == 4) {
              this.plan.weekList.splice(index, 1);
            }
          });
        }
      });
      this.checkWeek.forEach(element => {
        var temp = true;

        if (element == "星期六") {
          temp = false;
        }
        console.log(temp);
        if (temp) {
          this.plan.weekList.forEach((element3, index) => {
            if (element3.weekDay == 5) {
              this.plan.weekList.splice(index, 1);
            }
          });
        }
      });
      this.checkWeek.forEach(element => {
        var temp = true;

        if (element == "星期日") {
          temp = false;
        }
        console.log(temp);
        if (temp) {
          this.plan.weekList.forEach((element3, index) => {
            if (element3.weekDay == 6) {
              this.plan.weekList.splice(index, 1);
            }
          });
        }
      });

      //增加勾选的星期排课内容
      this.plan.weekList.forEach((element3, index) => {
        //周一
        if (element3.weekDay == 0) {
          //选中星期
          this.checkWeek.forEach(element => {
            var temp = true;

            if (element == "星期一") {
              temp = false;
            }
            console.log(temp);
            if (temp) {
              this.plan.weekList.splice(index, 1);
            }
          });
        }
      });

      this.checkWeek.forEach(element => {
        if (element == "星期一") {
          var temp = true;
          this.plan.weekList.forEach(element1 => {
            if (element1.weekDay == 0) {
              temp = false;
            }
          });

          //添加星期
          if (temp) {
            var temp1 = new Object();
            var temp2 = new Object();
            var dayPlan = new Array();
            temp1.time_id = this.timeList[0].time_id;
            temp1.deductionHours = "1";
            dayPlan.push(temp1);
            temp2.weekDay = 0;
            temp2.dayPlan = dayPlan;
            this.plan.weekList.push(temp2);

            // console.log(this.plan);
          }
        }

        if (element == "星期二") {
          var temp = true;
          this.plan.weekList.forEach(element1 => {
            if (element1.weekDay == 1) {
              temp = false;
            }
          });

          //添加星期
          if (temp) {
            var temp1 = new Object();
            var temp2 = new Object();
            var dayPlan = new Array();
            temp1.time_id = this.timeList[0].time_id;
            temp1.deductionHours = "1";
            dayPlan.push(temp1);
            temp2.weekDay = 1;
            temp2.dayPlan = dayPlan;
            this.plan.weekList.push(temp2);
          }
        }

        if (element == "星期三") {
          var temp = true;
          this.plan.weekList.forEach(element1 => {
            if (element1.weekDay == 2) {
              temp = false;
            }
          });

          //添加星期
          if (temp) {
            var temp1 = new Object();
            var temp2 = new Object();
            var dayPlan = new Array();
            temp1.time_id = this.timeList[0].time_id;
            temp1.deductionHours = "1";
            dayPlan.push(temp1);
            temp2.weekDay = 2;
            temp2.dayPlan = dayPlan;
            this.plan.weekList.push(temp2);
          }
        }

        if (element == "星期四") {
          var temp = true;
          this.plan.weekList.forEach(element1 => {
            if (element1.weekDay == 3) {
              temp = false;
            }
          });

          //添加星期
          if (temp) {
            var temp1 = new Object();
            var temp2 = new Object();
            var dayPlan = new Array();
            temp1.time_id = this.timeList[0].time_id;
            temp1.deductionHours = "1";
            dayPlan.push(temp1);
            temp2.weekDay = 3;
            temp2.dayPlan = dayPlan;
            this.plan.weekList.push(temp2);
          }
        }

        if (element == "星期五") {
          var temp = true;
          this.plan.weekList.forEach(element1 => {
            if (element1.weekDay == 4) {
              temp = false;
            }
          });

          //添加星期
          if (temp) {
            var temp1 = new Object();
            var temp2 = new Object();
            var dayPlan = new Array();
            temp1.time_id = this.timeList[0].time_id;
            temp1.deductionHours = "1";
            dayPlan.push(temp1);
            temp2.weekDay = 4;
            temp2.dayPlan = dayPlan;
            this.plan.weekList.push(temp2);
          }
        }

        if (element == "星期六") {
          var temp = true;
          this.plan.weekList.forEach(element1 => {
            if (element1.weekDay == 5) {
              temp = false;
            }
          });

          //添加星期
          if (temp) {
            var temp1 = new Object();
            var temp2 = new Object();
            var dayPlan = new Array();
            temp1.time_id = this.timeList[0].time_id;
            temp1.deductionHours = "1";
            dayPlan.push(temp1);
            temp2.weekDay = 5;
            temp2.dayPlan = dayPlan;
            this.plan.weekList.push(temp2);
          }
        }

        if (element == "星期日") {
          var temp = true;
          this.plan.weekList.forEach(element1 => {
            if (element1.weekDay == 6) {
              temp = false;
            }
          });

          //添加星期
          if (temp) {
            var temp1 = new Object();
            var temp2 = new Object();
            var dayPlan = new Array();
            temp1.time_id = this.timeList[0].time_id;
            temp1.deductionHours = "1";
            dayPlan.push(temp1);
            temp2.weekDay = 6;
            temp2.dayPlan = dayPlan;
            this.plan.weekList.push(temp2);
          }
        }
      });
    },

    //添加排课计划
    AddPlanItem(index1, index2, type) {
      var temp = new Object();
      temp.time_id = this.timeList[0].time_id;
      temp.deductionHours = "1";

      if (type == 0) {
        this.plan.weekList[index1].dayPlan.push(temp);
        console.log(this.plan.weekList[index1].dayPlan);
      } else {
        this.plan_interval.weekList[index1].dayPlan.push(temp);
        console.log(this.plan_interval.weekList[index1].dayPlan);
      }
    },

    //删除排课计划
    DelPlanItem(index1, index2, type) {
      if (type == 0) {
        this.plan.weekList[index1].dayPlan.splice(index2, 1);
        if (this.plan.weekList[index1].dayPlan.length == 0) {
          this.plan.weekList.splice(index1, 1);
        }
        this.checkWeek = [];
        this.plan.weekList.forEach(element => {
          if (element.weekDay == 0) {
            this.checkWeek.push("星期一");
          } else if (element.weekDay == 1) {
            this.checkWeek.push("星期二");
          } else if (element.weekDay == 2) {
            this.checkWeek.push("星期三");
          } else if (element.weekDay == 3) {
            this.checkWeek.push("星期四");
          } else if (element.weekDay == 4) {
            this.checkWeek.push("星期五");
          } else if (element.weekDay == 5) {
            this.checkWeek.push("星期六");
          } else if (element.weekDay == 6) {
            this.checkWeek.push("星期日");
          }
        });
      } else {
        this.plan_interval.weekList[index1].dayPlan.splice(index2, 1);
      }

      if (this.isEmpty(this.plan.weekList.length)) {
        console.log("隐藏按钮");
        // this.showSaveDisabled = true;
      } else {
        console.log("显示按钮");
        this.showSaveDisabled = false;
      }
    },

    /**
     *  排课展示接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 26;
      param.cid = this.cid;
      //排课方式（0每周排课 1隔天排课 2日历排课）
      param.planType = this.courseType;

      if (this.isEmpty(this.courseId)) {
        param.subId = "-1";
      } else {
        param.subId = this.courseId;
      }

      // console.log(this.levelId_1);
      if (this.isEmpty(this.levelId_1)) {
        param.levelId = "-1";
      } else {
        param.levelId = this.levelId_1;
      }

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then(res => {
          //显示内容视图
          this.loading = true;
          let resulte = res.data;
          if (resulte.state == 200) {
            this.checkWeek = [];
            //排课类型
            this.planType = resulte.data.plan.planType;

            // if(this.planType==2){
            //     this.$emit("goCalendar", "");
            // }

            this.$emit("getMaxPlan", resulte.data.maxPlanNum);

            //已有排课信息
            this.CourseItem = resulte.data.planRecord;

            //可选时段
            this.timeList = resulte.data.timeList;

            //可选教室
            this.roomList = resulte.data.roomList;

            if (this.roomList.length == 0 || this.timeList.length == 0) {
              this.showWarnMsg("请先在排课管理里设置上课时段和上课教室");
            }

            // 1隔天排课
            if (this.courseType == 1) {
              //间隔排课数据
              this.plan_interval = resulte.data.plan;

              if (resulte.data.plan.weekList.length == 0) {
                var temp1 = new Object();
                var temp2 = new Object();
                var dayPlan = new Array();
                temp1.time_id = this.timeList[0].time_id;
                temp1.deductionHours = "1";
                dayPlan.push(temp1);

                temp2.dayPlan = dayPlan;
                this.plan_interval.weekList.push(temp2);
              }
            }

            if (resulte.data.plan == null) {
              this.plan = new Object();

              var weekList = new Array();

              this.plan.weekList = weekList;

              this.plan.lessonHour = "";
              this.plan.skipHoliday = "";
              this.plan.beginTime = null;
              this.plan.lessonHour = "";
              this.plan.interval = "";
              this.plan.beginTime = "";
            } else {
              //每周排课数据
              this.plan = resulte.data.plan;

              if (this.isEmpty(this.plan.weekList)) {
                console.log("隐藏按钮");
                // this.showSaveDisabled = true;
              } else {
                console.log("显示按钮");
                this.showSaveDisabled = false;
              }

              //课时
              if (resulte.data.plan.lessonHour != null) {
                this.courseTime = resulte.data.plan.lessonHour;
              }

              //间隔天数
              this.day = resulte.data.plan.interval;
              //排课开始日期
              this.selectDate = resulte.data.plan.beginTime;

              //同步状态
              this.plan.weekList.forEach(element => {
                if (element.weekDay == 0) {
                  this.checkWeek.push("星期一");
                } else if (element.weekDay == 1) {
                  this.checkWeek.push("星期二");
                } else if (element.weekDay == 2) {
                  this.checkWeek.push("星期三");
                } else if (element.weekDay == 3) {
                  this.checkWeek.push("星期四");
                } else if (element.weekDay == 4) {
                  this.checkWeek.push("星期五");
                } else if (element.weekDay == 5) {
                  this.checkWeek.push("星期六");
                } else if (element.weekDay == 6) {
                  this.checkWeek.push("星期日");
                }
              });

              if (resulte.data.plan.skipHoliday == 1) {
                this.skipHoliday = true;
              } else {
                this.skipHoliday = false;
              }
            }

            console.log(resulte);
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch(err => {
          this.loading = true;
          console.log("请求数据异常");
          console.log(err);
        });
    },

    //取消按钮
    close() {
      this.$router.push({ path: "/Home/ClassManager" });
    },

    //提交排课计划接口
    doCommitAction() {
      this.PlanJson = "";
      if (this.courseType == 0) {
        //每日排课
        console.log(this.plan);

        //判断排课内容非空
        // if(this.isEmpty(this.plan.weekList)){
        //   this.showWarnMsg("请完善排课信息");
        //   return;
        // }

        var temp = false;
        //判断是否选教室
        this.plan.weekList.forEach(element => {
          element.dayPlan.forEach(element1 => {
            if (element1.room_id == null || element1.room_id == "") {
              temp = true;
              return;
            }
          });
        });
        if (temp) {
          this.showWarnMsg("请选择上课教室");
          return;
        }

        if (this.isEmpty(this.selectDate)) {
          this.showWarnMsg("请选择排课开始日期");
          return;
        }

        if (this.isEmpty(this.courseTime)) {
          this.showWarnMsg("请输入排课课时");
          return;
        }
        if (this.isEmpty(this.levelId_1)) {
          this.showWarnMsg("请选择课程级别");
          return;
        }
        this.plan.beginTime = this.selectDate;
        this.plan.lessonHour = this.courseTime;
        this.plan.planType = this.courseType;
        if (this.skipHoliday) {
          this.plan.skipHoliday = "1";
        } else {
          this.plan.skipHoliday = "0";
        }
        //转换json
        this.PlanJson = JSON.stringify(this.plan);
      } else {
        console.log(this.plan_interval);
        //隔日排课
        var temp = false;
        //判断是否选中所以项目
        this.plan_interval.weekList.forEach(element => {
          element.dayPlan.forEach(element1 => {
            if (element1.room_id == null || element1.room_id == "") {
              temp = true;
              return;
            }
          });
        });
        if (temp) {
          this.showWarnMsg("请选择上课教室");
          return;
        }

        if (this.isEmpty(this.selectDate)) {
          this.showWarnMsg("请选择排课开始日期");
          return;
        }

        if (this.isEmpty(this.courseTime)) {
          this.showWarnMsg("请输入排课课时");
          return;
        }

        if (this.isEmpty(this.day) || this.day == -1) {
          this.showWarnMsg("请选择相隔天数");
          return;
        }

        if (this.isEmpty(this.levelId_1)) {
          this.showWarnMsg("请选择课程级别");
          return;
        }
        // if(this.isEmpty(this.lessonId)){
        //   this.showWarnMsg("请选择课次名称");
        //   return;
        // }
        this.plan_interval.beginTime = this.selectDate;
        this.plan_interval.lessonHour = this.courseTime;
        this.plan_interval.planType = this.courseType;
        this.plan_interval.interval = this.day;
        if (this.skipHoliday) {
          this.plan_interval.skipHoliday = "1";
        } else {
          this.plan_interval.skipHoliday = "0";
        }

        //转换json
        this.PlanJson = JSON.stringify(this.plan_interval);
      }

      this.showCommitLoading = true;

      let param = this.getHttpParams();
      param.type = 28;
      param.cid = this.cid;
      //排课JSON
      param.plan = this.PlanJson;
      param.subId = this.courseId;

      // console.log("排课JSON是:", this.PlanJson);

      // console.log(this.levelId_1);
      if (this.isEmpty(this.levelId_1)) {
        param.levelId = "-1";
      } else {
        param.levelId = this.levelId_1;
      }

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then(res => {
          //显示内容视图
          this.loading = true;
          let resulte = res.data;
          this.showCommitLoading = false;
          if (resulte.state == 200) {
            this.showSuccessMsg("保存成功");

            this.$router.push({ path: "/Home/ClassManager" });
            console.log(resulte);
          } else if (resulte.state == 406) {
            this.showWarnMsg(
              "所选日期（" +
                resulte.data.class_date +
                "）/时段（" +
                resulte.data.time_range +
                "）/教室（" +
                resulte.data.room_name +
                "）/老师（" +
                resulte.data.teacher_name +
                "）有冲突，请检查！"
            );
          } else if (resulte.state == 416) {
            this.showWarnMsg("请至少排课" + resulte.data + "个课时");
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch(err => {
          this.showCommitLoading = false;
          console.log("请求数据异常");
        });
    },
    //切换类型
    changeType() {
      if (this.isEmpty(this.courseId)) {
        this.showWarnMsg("请先选择课程及对应的课程级别");

        return;
      } else {
        if (this.isEmpty(this.levelId_1)) {
          this.showWarnMsg("请选择课程对应的级别");

          return;
        }
      }

      // console.log(this.courseType);

      this.httpData();

      //排课方式（0每周排课 1隔天排课 2日历排课）
      if (this.courseType == 1) {
        this.showSaveDisabled = false;
      }
    },

    //输入正整数
    handleEdit(e) {
      let value = e.replace(/[^\d]+/g, ""); // 以0开头或者输入非数字，会被替换成空
      value = value.replace(/(\d{9})\d*/, "$1"); // 最多保留9位整数
      this.courseTime = value;
      if (this.isEmpty(this.courseId)) {
        this.showWarnMsg("请先选择课程及对应的课程级别");

        return;
      } else {
        if (this.isEmpty(this.levelId_1)) {
          this.showWarnMsg("请选择课程对应的级别");

          return;
        }
      }
    }
  },
  computed: {},
  components: {},
  props: ["cid", "courseId", "levelId", "lessonId"],
  watch: {
    //监听输入框值的变化
    levelId_1(newValue, oldValue) {
      if (newValue == "" || newValue == null) {
        // this.levelId_1 = "-1";
      }
      this.httpData();
    },
    levelId(newValue, oldValue) {
      this.levelId_1 = newValue;
    }
  }
};
</script>
<style lang="scss" scoped>
.smart_manage_course {
  .temp_student {
    margin-bottom: 5px;
    float: right;
  }

  .select_item {
    width: 180px;
  }
  .input_item {
    width: 180px;
  }

  .course_info {
    margin-top: -10px;
    margin-left: 60%;
    position: absolute;
  }
  .checkbox-group {
    margin-top: 10px;
    margin-bottom: 6px;
    display: inline-block;
  }
  .Plan {
    width: 1100px;
    min-width: 1100px;
    height: 20px;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 15px;
    background: #d1d1d1;
    display: grid;
    text-align: center;
    grid-template-columns: 100px 300px 300px 300px 100px;
  }

  .plan_day {
    width: 1000px;
    min-width: 1000px;
    height: 20px;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 15px;
    background: #d1d1d1;
    display: grid;
    text-align: center;
    grid-template-columns: 200px 300px 300px 100px;
  }

  .plan_action {
    display: grid;
    grid-template-columns: 100px 300px 300px 300px 100px;
  }

  .plan_action_day {
    display: grid;
    grid-template-columns: 200px 300px 300px 100px;
  }

  .List_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
  .botton_ctrl {
    margin-left: 12px;
    cursor: pointer;
  }

  .show {
    visibility: visible;
  }
  .hidden {
    visibility: hidden;
  }

  .bom_action {
    width: 1100px;
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .cancel,
    .commit {
      width: 100px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }

    .cancel {
      background: #dcdcdc;
      margin-right: 30px;
      color: black;
    }

    .commit {
      background: #409eff;
      color: white;
    }

    .commit_disable {
      width: 120px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: #dcdcdc;

      color: black;
    }
  }
}
</style>
