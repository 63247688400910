// 自定义的排课日历组件
<template>
  <div class="custom_calendar">
    <!-- 年月  -->
    <div class="head_content">
      <span class="year">{{ calendarObj.year }}</span>
      <span class="month margin_left_12">{{ calendarObj.month }}</span>
    </div>
    <!-- 星期几 -->
    <div class="week_day">
      <div :class="['week_info', { tv_red: index == 0 || index == 6 }]" v-for="(item, index) in weekArr" :key="index">
        {{ item }}
      </div>
    </div>

    <!-- 日期展示 -->
    <div class="day_content">
      <div class="day_info" v-for="(item, index) in calendarObj.dayArr" :key="index">
        <span @click="onClickDay(item)" :class="['day_content', { day_holidayList: isHoliday(item.date) }, { day_selected: item.isSelected }, 'cursor_p']">{{
          item.dayValue
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      weekArr: ["日", "一", "二", "三", "四", "五", "六"],
    };
  },
  created() {},
  mounted() {
    //   同步选中状态
    this.initData();
  },
  methods: {
    //同步状态初始化
    initData() {
      if (!this.isEmpty(this.plan)) {
        this.calendarObj.dayArr.forEach((element) => {
          element.isSelected = false;
          this.plan.weekList.forEach((dayPlan) => {
            if (element.date == dayPlan.class_date) {
              element.isSelected = true;
              console.log(element.date);

              console.log(dayPlan.class_date);
            } else {
              // if(element.isSelected == true){
              //   element.isSelected = false;
              //     console.log(element.date);
              // console.log(dayPlan.class_date);
              // }
            }
          });
        });
      }
    },
    /**
     * 日期的点击事件
     */
    onClickDay(item) {
      console.log(this.calendarObj);
      if (this.isEmpty(item.date)) {
        return;
      }
      if (this.isHoliday(item.date)) {
        return;
      }

      if (this.isTodayAfter(item.date)) {
        this.showWarnMsg("无法对过去及当天的日期进行排课");
        return;
      }

      if (this.isEmpty(this.courseId)) {
      this.showWarnMsg("请先选择课程及对应的课程级别");

      return;
    } else {
      if (this.isEmpty(this.levelId)) {
        this.showWarnMsg("请选择课程对应的级别");

        return;
      }
    }

      //如果当前是允许点击的则取反
      // item.isSelected = !item.isSelected;

      //给一个标识,是选中还是取消 选中  2取消
      // let state = item.isSelected ? 1 : 2;
      if (!item.isSelected) {
        item.isSelected = true;
        let state = 1;

        console.log(item);
        //调用父组件的事件
        this.$emit("onDayClickEvent", { dateStr: item.date, state });
      } else {
        item.isSelected = false;
        let state = 0;

        console.log(item);
        //调用父组件的事件
        this.$emit("onDayClickEvent", { dateStr: item.date, state });
      }
    },

    //判断日期是否今天以后
    isTodayAfter(date) {
      var istodayAfter = false;
      let temp = new Date(date).getTime();
      let today = new Date().getTime();
      if (temp < today) {
        istodayAfter = true;
      }

      return istodayAfter;
    },

    //判断是否属于节假日
    isHoliday(date) {
      var isholiday = false;
      if (this.skipHoliday) {
        this.holidayList.forEach((element) => {
          if (element == date) {
            isholiday = true;
          }
        });
      }
      // console.log(date);
      return isholiday;
    },
    /**
     * 根据中文月份获取阿拉布数字月份
     */
    getMonthIndexByValue(monthValue) {
      let monthArr = ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"];

      let index = monthArr.findIndex((item) => {
        return item == monthValue;
      });

      let res = index + 1;
      if (res < 10) {
        res = "0" + res;
      }
      return res;
    },
  },
  computed: {},
  components: {},
  //需要传入年和月
  props: ["calendarObj", "holidayList", "plan", "skipHoliday", "levelId","courseId"],
  watch: {
    //监听输入框值的变化
    plan(newValue, oldValue) {
      //   同步选中状态
      this.initData();
    },
  },
};
</script>
<style lang="scss" scoped>
.custom_calendar {
  width: 94%;
  border: 1px solid #d7d7d7;
  border-radius: 2px;
  margin-bottom: 5px;
  .head_content {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .year {
      font-weight: bold;
    }
  }
  .week_day {
    width: 100%;
    height: 10px;
    display: flex;
    background: rgba(0, 0, 0, 0.05);
    .week_info {
      width: 14.3%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .day_content {
    margin: 2px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .day_info {
      width: 14.2%;
      // height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    //日期的基本宽高
    .day_content {
      width: 20px;
      height: 18px;
      display: flex;
      border-radius: 2px;
      font-size: 12px;
      align-items: center;
      justify-content: center;
    }
    .day_selected {
      background: #169bd5;
      color: white;
    }
    .day_holidayList {
      background: #ff0000;
      color: white;
    }

    //鼠标禁用样式
    .cursor_dis {
      cursor: not-allowed;
    }

    //鼠标非禁用样式
    .cursor_p {
      cursor: pointer;
    }
  }
}
</style>
