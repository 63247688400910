//处理日历展示的工具类
export default {
  methods: {
    /**
     * 动态创建某一年的12个月份的日历数据
     * @param year  某一年
     * @param holidaysArr 节假日的数组
     */
    initCalendarData(year, holidaysArr) {
      let monthArr = []
      //循环12个月的数据
      for (let i = 1; i < 13; i++) {
        //创建一个日历对象
        let calendarObj = new Object()

        //日历对象的年份
        calendarObj.year = year

        //日历对象的月份
        calendarObj.month = this.getMonthWithIndex(i - 1)

        //日历对象的天数数组
        let dayArr = []

        //获取月有多少天,1-9月获取天数的时候前面补个0
        let month = i < 10 ? `0${i}` : i
        let days = this.getDaysInMonth(year, month);

        //获取当月的1号是星期几
        let dateStr = year + "-" + month + "-" + "01";

        let index = this.getWeek(dateStr);

        //创建7*6排 42个展示天的对象
        for (let i = 0; i < 42; i++) {
          let obj = new Object();
          if (i >= index && i < index + days) {

            //日期值
            let day = i - index + 1;
            obj.dayValue = day;

            //赋值完整的年月日的值,用于做比对标识
            let dayStr = day < 10 ? `0${day}` : day
            let date = year + "-" + month + "-" + dayStr;
            obj.date = date

            //是否选中,这里初始化的时候需要根据当前日期是否已经标记为假日的标识来处理是否选中
            if (!this.isEmpty(holidaysArr) && holidaysArr.length > 0) {
              let pos = this.holidaysArr.findIndex(holidaysItem => {
                return holidaysItem.holidayDate == date
              })
              if (pos != -1) {
                //表示节假日已经有日期
                obj.isSelected = true;
              } else {
                //表示节假日中没有该日期
                obj.isSelected = false;
              }
            } else {
              //没有节假日全部赋值为false
              obj.isSelected = false;
            }

          } else {
            //日期值
            obj.dayValue = "";
            obj.date = "";
            obj.isSelected = false;
          }
          dayArr.push(obj);
        }
        calendarObj.dayArr = dayArr
        monthArr.push(calendarObj)
      }
      return monthArr
    },

    //根绝索引获取中文月份
    getMonthWithIndex(index) {
      let monthArr = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
      return monthArr[index]
    }
  }
}
