//日历排课
<template>
  <div class="set_holidays">
    <!-- 日历展示区域和所选日期显示显示列表 -->
    <div class="bom_content">
      <!-- 日历展示区域 -->
      <div class="left_calendar margin_top_6">
        <div>
          <span class="margin_top_0"> 排课日期：{{ CourseItem.beginTime != null ? CourseItem.beginTime + " 至 " + CourseItem.endTime : "" }} </span>
          <span class="margin_left_40">已排课次：{{ CourseItem.planNum }}</span>
          <span class="margin_left_40">已上课次：{{ CourseItem.onNum }}</span>
          <span class="margin_left_40">已取消课次：{{ CourseItem.cancelNum }}</span>
          <div class="course_item">
            <el-checkbox class="margin_top_0" v-model="skipHoliday" @change="changeSkip">自动跳过节假日</el-checkbox>
            <div class="com_item">
              <span class="color_exmple1"></span>
              <span class="text margin_left_4">可选</span>
            </div>

            <div class="com_item">
              <span class="color_exmple2"></span>
              <span class="text margin_left_4">已选</span>
            </div>
            <div class="com_item">
              <span class="color_exmple3"></span>
              <div class="text margin_left_4">节假日</div>
            </div>
          </div>
        </div>

        <!-- 顶部的年份切换以及保存按钮 -->
        <div class="top_memu margin_top_4 margin_bom_10">
          <!-- 中间的标题以及切换箭头 -->
          <div class="arrow_time">
            <div @click.stop="doSubYear" :class="['top_arrow', 'el-icon-arrow-left', currYear == year ? 'off_cursor' : 'on_cursor']"></div>
            <div class="year_name">{{ year }}年</div>
            <div @click.stop="doAddYear" class="on_cursor top_arrow el-icon-arrow-right"></div>
          </div>
        </div>
        <!-- 日历展示区域 -->
        <el-scrollbar v-if="httploading">
          <div class="calendar_content" v-if="calendarShow">
            <div class="item_calendar" v-for="(item, index) in monthArr" :key="index">
              <div class="calendar_content">
                <Calendar
                  @onDayClickEvent="onDayClickEvent"
                  :calendarObj="item"
                  :holidayList="holidayList"
                  :plan="plan"
                  :skipHoliday="skipHoliday"
                  :levelId="levelId"
                  :courseId="courseId"
                ></Calendar>
              </div>
            </div>
          </div>

          <!-- 排课列表 -->
          <div>
            <div class="Plan">
              <span class="PlanItem_first">日期</span>
              <span class="PlanItem_second">时段</span>
              <span class="PlanItem">教室</span>
              <span class="PlanItem">扣除课时数</span>
              <span class="PlanItem">操作</span>
            </div>

            <div class="PlanList" v-if="httploading">
              <div v-for="(PlanItem, index1) in plan.weekList" :key="index1">
                <div class="plan_action" v-for="(dayItem, index2) in PlanItem.dayPlan" :key="index2">
                  <!-- 星期 -->
                  <span class="List_item List_item_first" :class="[index2 == 0 ? 'show' : 'hidden']"> {{ PlanItem.class_date }}</span>

                  <!-- 时段选择 -->
                  <span :class="['List_item', index2 == 0 ? 'List_item_first' : 'List_item_second']">
                    <el-select v-model="dayItem.time_id" placeholder="请选择时段" size="small" :disabled="istoday(PlanItem.class_date)">
                      <el-option v-for="item in timeList" :key="item.time_id" :label="item.time_range" :value="item.time_id"> </el-option>
                    </el-select>
                  </span>

                  <!-- 教室选择 -->
                  <span class="List_item">
                    <el-select v-model="dayItem.room_id" placeholder="请选择教室" size="small" :disabled="istoday(PlanItem.class_date)">
                      <el-option v-for="item in roomList" :key="item.room_id" :label="item.room_name" :value="item.room_id"> </el-option>
                    </el-select>
                  </span>

                  <!-- 扣除课时数 -->
                  <span class="List_item">
                    <el-select v-model="dayItem.deductionHours" placeholder="请选择扣除课时数" size="small">
                      <el-option v-for="item in numList" :key="item.deductionHours" :label="item.num_name" :value="item.deductionHours"> </el-option>
                    </el-select>
                  </span>

                  <!-- 添加删除 -->
                  <span class="List_item" v-if="!istoday(PlanItem.class_date)">
                    <span class="botton_ctrl iconfont icon-tianjia1" @click="AddPlanItem(index1, index2)"></span>
                    <span class="botton_ctrl iconfont icon-shanchu" @click="DelPlanItem(index1, index2)"></span>
                  </span>
                </div>
              </div>
            </div>

            <div class="bom_action">
              <!-- 取消 -->
              <div class="cancel" @click.stop="close"><span>取</span><span style="margin-left: 16px">消</span></div>
              <!-- 确定 -->
              <el-button
                :loading="showCommitLoading"
                :class="[showSaveDisabled ? 'commit_disable' : 'commit']"
                @click.stop="doCommitAction"
                :disabled="showSaveDisabled"
              >
                <span>保存并返回</span>
              </el-button>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import Calendar from "@/components/calendar/CalendarScheduling.vue";
//引入日历初始化
import CalendarUtil from "@/mixin/CalendarUtil.js";
export default {
  mixins: [CalendarUtil],
  data() {
    return {
      //保存按钮是否隐藏
      showSaveDisabled: false,
      calendarShow: true,
      httploading: false,
      showCommitLoading: false,
      //每周排课计划集合
      plan: null,
      //提交json
      PlanJson: "",
      //保存设置的loading框
      loading: false,

      //根据选择的年份创建当年12个月的日历数据
      monthArr: [],

      //切换的年份
      year: -1,

      //当前日期的年份
      currYear: -1,

      //班级排课属性
      CourseItem: {},
      //时段数组
      timeList: [],
      //课时数字
      numList: [
        {
          deductionHours: 1,
          num_name: "1"
        },
        {
          deductionHours: 2,
          num_name: "2"
        },
        {
          deductionHours: 3,
          num_name: "3"
        },
        {
          deductionHours: 4,
          num_name: "4"
        },
        {
          deductionHours: 5,
          num_name: "5"
        },
        {
          deductionHours: 6,
          num_name: "6"
        },
        {
          deductionHours: 7,
          num_name: "7"
        },
        {
          deductionHours: 8,
          num_name: "8"
        },
        {
          deductionHours: 9,
          num_name: "9"
        },
        {
          deductionHours: 10,
          num_name: "10"
        }
      ],
      //教室数组
      roomList: [],
      //节假日列表
      holidayList: [],
      //跳过节假日
      skipHoliday: false
    };
  },
  created() {
    //初始化数据
    this.initData();
  },
  mounted() {
    this.httpData();

    if (this.isEmpty(this.courseId)) {
      this.showWarnMsg("请先选择课程及对应的课程级别");

      return;
    } else {
      if (this.isEmpty(this.levelId)) {
        this.showWarnMsg("请选择课程对应的级别");

        return;
      }
    }
  },
  methods: {
    changeSkip() {
      if (this.isEmpty(this.courseId)) {
        this.showWarnMsg("请先选择课程及对应的课程级别");

        return;
      } else {
        if (this.isEmpty(this.levelId)) {
          this.showWarnMsg("请选择课程对应的级别");

          return;
        }
      }
    },
    //判断传入时间是否是今天之后的
    istoday(str) {
      let d = new Date(str.replace(/-/g, "/"));
      let todaysDate = new Date();
      if (d.setHours(0, 0, 0, 0) > todaysDate.setHours(0, 0, 0, 0)) {
        return false;
      } else {
        return true;
      }
    },
    initData() {
      //获取当前的年份
      this.currYear = new Date().getFullYear();
      this.year = this.currYear;
      //根据年来初始化日历数据
      this.sysCalendarData();
    },

    httpData() {
      let param = this.getHttpParams();
      param.type = 26;
      param.cid = this.cid;
      //排课方式（0每周排课 1隔天排课 2日历排课）
      param.planType = "2";
      // param.subId = this.courseId;

      if (this.isEmpty(this.courseId)) {
        param.subId = "-1";
      } else {
        param.subId = this.courseId;
      }
      if (this.isEmpty(this.levelId)) {
        param.levelId = "-1";
      } else {
        param.levelId = this.levelId;
      }

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then(res => {
          //显示内容视图

          this.httploading = true;
          this.loading = true;
          let resulte = res.data;

          if (resulte.state == 200) {
            this.$emit("getMaxPlan", resulte.data.maxPlanNum);
            //已有排课信息
            this.CourseItem = resulte.data.planRecord;

            //可选时段
            this.timeList = resulte.data.timeList;

            //可选教室
            this.roomList = resulte.data.roomList;

            if (this.roomList.length == 0) {
              this.showWarnMsg("请先在排课管理里设置上课教室");
            }
            if (this.timeList.length == 0) {
              this.showWarnMsg("请先在排课管理里设置上课时段");
            }

            //每周排课数据
            this.plan = resulte.data.plan;
            //节假日列表
            this.holidayList = resulte.data.holidayList;

            if (resulte.data.plan.skipHoliday == 1) {
              this.skipHoliday = true;
            } else {
              this.skipHoliday = false;
            }

            if (this.plan.weekList.length == 0) {
              //初始化数据
              // this.initData();
              this.sysCalendarData();
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch(err => {
          this.loading = true;
        });
    },

    //取消按钮
    close() {
      this.$router.push({ path: "/Home/ClassManager" });
    },
    //提交排课计划接口
    doCommitAction() {
      this.PlanJson = "";

      //日历排课

      var temp = false;
      //判断是否选中所以项目
      this.plan.weekList.forEach(element => {
        element.dayPlan.forEach(element1 => {
          if (element1.room_id == null) {
            temp = true;
            return;
          }
        });
      });
      if (temp) {
        this.showWarnMsg("请选择上课教室");
        return;
      }

      if (this.isEmpty(this.levelId)) {
        this.showWarnMsg("请选择课程级别");
        return;
      }

      this.plan.planType = "2";
      if (this.skipHoliday) {
        this.plan.skipHoliday = "1";
      } else {
        this.plan.skipHoliday = "0";
      }
      //转换json
      this.PlanJson = JSON.stringify(this.plan);

      this.showCommitLoading = true;

      let param = this.getHttpParams();
      param.type = 27;
      param.cid = this.cid;
      //排课JSON
      param.plan = this.PlanJson;
      param.subId = this.courseId;

      if (this.isEmpty(this.levelId)) {
        param.levelId = "-1";
      } else {
        param.levelId = this.levelId;
      }
      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then(res => {
          //显示内容视图
          this.loading = true;
          let resulte = res.data;
          this.showCommitLoading = false;
          if (resulte.state == 200) {
            this.showSuccessMsg("保存成功");

            this.$router.push({ path: "/Home/ClassManager" });
          } else if (resulte.state == 406) {
            this.showWarnMsg(
              "所选日期（" +
                resulte.data.class_date +
                "）/时段（" +
                resulte.data.time_range +
                "）/教室（" +
                resulte.data.room_name +
                "）/老师（" +
                resulte.data.teacher_name +
                "）有冲突，请检查！"
            );
          } else if (resulte.state == 416) {
            this.showWarnMsg("请至少排课" + resulte.data + "个课时");
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch(err => {
          this.showCommitLoading = false;
        });
    },
    //添加排课计划
    AddPlanItem(index1, index2) {
      var temp = new Object();
      temp.time_id = this.timeList[0].time_id;

      this.plan.weekList[index1].dayPlan.push(temp);

      if (!this.isEmpty(this.plan.weekList)) {
        this.showSaveDisabled = false;
      }
    },
    //删除排课计划
    DelPlanItem(index1, index2) {
      this.plan.weekList[index1].dayPlan.splice(index2, 1);
      if (this.plan.weekList[index1].dayPlan.length == 0) {
        this.plan.weekList.splice(index1, 1);
      }

      this.monthArr = [];

      this.calendarShow = false;
      this.initData();

      setTimeout(() => {
        this.calendarShow = true;
      }, 0);

      if (!this.isEmpty(this.plan.weekList)) {
        this.showSaveDisabled = false;
      }
    },
    //减年
    doSubYear() {
      if (this.year > this.currYear) {
        this.year--;
      }
      //在同步日历的数据
      this.sysCalendarData();
      this.httpData();
    },

    //加年
    doAddYear() {
      this.year++;
      //同步日历的数据
      this.sysCalendarData();
      this.httpData();
    },

    //同步日历的数据
    sysCalendarData() {
      this.monthArr.splice(0, this.monthArr.length);
      this.monthArr = this.monthArr.concat(this.initCalendarData(this.year, this.holidaysArr));
    },

    //日历的天的点击事件
    onDayClickEvent(obj) {
      if (this.isEmpty(this.courseId)) {
        this.showWarnMsg("请先选择课程及对应的课程级别");

        return;
      } else {
        if (this.isEmpty(this.levelId)) {
          this.showWarnMsg("请选择课程对应的级别");

          return;
        }
      }
      if (this.roomList.length == 0 || this.timeList.length == 0) {
        this.showWarnMsg("请先在排课管理里设置上课时段和上课教室");
        return;
      }
      //获取点击日历位置的日期2022-03-05
      let date = obj.dateStr;

      //给一个时间戳用于排序
      let temp = new Date(date).getTime();

      //获取是选中还是取消的状态 1选中 2取消
      let state = obj.state;

      //判断是选中还是取消
      if (state == 1) {
        //添加排课
        var temp1 = new Object();
        var temp2 = new Object();
        var dayPlan = new Array();
        temp1.time_id = this.timeList[0].time_id;
        temp1.deductionHours = "1";
        dayPlan.push(temp1);
        temp2.class_date = date;
        temp2.dayPlan = dayPlan;
        this.plan.weekList.push(temp2);

        if (!this.isEmpty(this.plan.weekList)) {
          this.showSaveDisabled = false;
        }
      } else {
        //取消排课

        this.plan.weekList.forEach((element, index) => {
          var selectday = false;
          if (obj.dateStr == element.class_date) {
            selectday = true;
          }

          if (selectday) {
            this.plan.weekList.splice(index, 1);
          }
        });
      }
    }
  },
  computed: {},
  components: {
    Calendar
  },
  props: ["cid", "courseId", "levelId", "lessonId"],
  watch: {
    //监听输入框值的变化
    skipHoliday(newValue, oldValue) {
      if (this.skipHoliday) {
        //删除节假日排课

        this.plan.weekList.forEach((element, index) => {
          var isHoliday = false;
          this.holidayList.forEach(date => {
            if (date == element.class_date) {
              isHoliday = true;
              console.log(date);
              console.log(element.class_date);
            }
            if (isHoliday) {
              this.plan.weekList.splice(index, 1);
              console.log(this.plan.weekList);
            }
          });
        });

        this.monthArr = [];

        this.calendarShow = false;
        this.sysCalendarData();

        setTimeout(() => {
          this.calendarShow = true;
        }, 0);
      }
    },

    levelId(newValue, oldValue) {
      this.httpData();
    }
  }
};
</script>
<style lang="scss" scoped>
.set_holidays {
  .top_memu {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .arrow_time {
      display: flex;
      align-items: center;
      justify-content: center;
      .top_arrow {
        font-size: 16px;
      }
      .year_name {
        margin: 0 14px;
        color: black;
        font-weight: bold;
      }

      //禁用的鼠标样式
      .off_cursor {
        cursor: not-allowed;
      }

      .on_cursor {
        cursor: pointer;
      }
    }

    .right_content {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  //底部的内容部分
  .bom_content {
    display: flex;
    .el-scrollbar {
      height: calc(100vh - 76px - 60px - 180px);
    }

    ::v-deep .el-scrollbar__wrap {
      overflow-y: auto;
      overflow-x: hidden;
    }

    //日历展示区域
    .left_calendar {
      width: 100%;
      .course_item {
        margin-top: 10px;
        display: flex;

        .com_item {
          display: flex;
          align-items: center;
          margin-left: 40px;
        }

        .color_exmple1 {
          display: inline-block;
          border: 0.5px solid #d7d7d7;
          width: 14px;
          height: 13px;
        }
        .color_exmple2 {
          display: inline-block;
          background: #169bd5;
          width: 15px;
          height: 14px;
        }
        .color_exmple3 {
          display: inline-block;
          background: #ff0000;
          width: 15px;
          height: 14px;
        }
        .text {
          display: inline-block;
          font-size: 16px;
        }
      }

      .calendar_content {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        overflow-y: auto;

        .item_calendar {
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;

          .calendar_content {
            width: 95%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .Plan {
        width: 1100px;
        min-width: 1100px;
        height: 20px;
        margin-top: 5px;
        padding-top: 10px;
        padding-bottom: 15px;
        background: #d1d1d1;
        display: grid;
        text-align: center;
        grid-template-columns: 100px 300px 300px 300px 100px;
      }

      .plan_action {
        display: grid;
        grid-template-columns: 100px 300px 300px 300px 100px;
      }

      .List_item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
      }
      .botton_ctrl {
        margin-left: 12px;
        cursor: pointer;
      }

      .show {
        visibility: visible;
      }
      .hidden {
        visibility: hidden;
      }

      .bom_action {
        width: 1100px;
        margin-top: 10px;
        height: 48px;
        display: flex;
        justify-content: center;
        .cancel,
        .commit {
          width: 100px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        }

        .cancel {
          background: #dcdcdc;
          margin-right: 30px;
          color: black;
        }

        .commit {
          background: #409eff;
          color: white;
        }
        .commit_disable {
          width: 120px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: #dcdcdc;

          color: black;
        }
      }
    }

    //右边展示选中日期的列表部分
    .right_list {
      width: 28%;
      height: calc(100vh - 76px - 60px);
      display: flex;
      flex-direction: column;
      .right_save_bt {
        height: 50px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .choose_content {
        flex: 1;
        position: relative;
        margin-left: 20px;
        margin-right: 10px;

        .choose_date_list {
          display: flex;
          flex-direction: column;
          margin-top: 10px;

          .item_holidays {
            height: 50px;
            display: flex;
            align-items: center;

            //时间
            .time_str {
              font-weight: bold;
              margin-right: 20px;
            }

            ::v-deep .el-input__inner {
              height: 32px;
              line-height: 32px;
              padding-left: 6px;
            }

            ::v-deep .el-input__icon {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            ::v-deep .el-input {
              width: 160px;
            }

            ::v-deep .el-input__suffix {
              right: 0px;
            }

            //删除
            .del {
              margin-left: 20px;
              color: #409eff;
              cursor: pointer;
              font-size: 20px;
            }
          }
        }

        .empty_view {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
