//排课
<template>
  <div class="course_away_record">
    <!-- 班级信息 -->
    <div class="margin_top_10">
      <span>班级名称：{{ item.class_name }}</span>
      <span class="margin_left_20">班级ID：{{ item.class_id }}</span>
      <span class="margin_left_20">班级类型：{{ item.type == 1 ? "正式课班级" : "试听课班级" }}</span>
      <span class="margin_left_20"
        >课程名称:
        <el-select v-model="courseId" placeholder="请选择" size="small">
          <el-option v-for="(item, index) in item.subList" :key="item.subId" :label="item.subName" :value="item.subId" @click.native="changeCourse1(index)">
          </el-option>
        </el-select>
      </span>
      <span class="margin_left_20"
        >课程级别:

        <el-select v-model="levelId" placeholder="请选择" size="small">
          <el-option v-for="(item, index) in levelList" :key="item.levelId" :label="item.levelName" :value="item.levelId" @click.native="changeCourse2(index)">
          </el-option>
        </el-select>
      </span>

      <div class="margin_top_4">
        <span v-if="max_plan_num != ''">最大可排课次数：{{ max_plan_num }}</span>
        <span :class="{ margin_left_20: max_plan_num != '' }">班级老师：{{ item.teacher_name }}</span>
        <span class="margin_left_20">实招/预招：{{ item.class_num }}/{{ item.plan_num }}</span>
      </div>
    </div>

    <!-- 切换按钮 -->
    <div class="action">
      <el-tabs v-model="radio">
        <el-tab-pane label="智能排课" name="1"></el-tab-pane>
        <span class="margin_left_40"></span>
        <el-tab-pane label="日历排课" name="2"></el-tab-pane>
      </el-tabs>
    </div>

    <!-- 智能排课 @getMaxPlan="getMaxPlanNum"-->
    <SmartManageCourse v-if="radio == 1 && isHttp" :cid="item.class_id" :courseId="courseId" :levelId="levelId" :lessonId="lessonId"></SmartManageCourse>

    <!-- 日历排课  @getMaxPlan="getMaxPlanNum"-->
    <CalendarManageCourse v-if="radio == 2 && isHttp" :cid="item.class_id" :courseId="courseId" :levelId="levelId" :lessonId="lessonId"></CalendarManageCourse>
  </div>
</template>
<script>
import SmartManageCourse from "@/views/educationalcenter/classmanager/SmartManageCourse";
import CalendarManageCourse from "@/views/educationalcenter/classmanager/CalendarManageCourse.vue";
export default {
  data() {
    return {
      isHttp: false,
      radio: "1",
      item: null,
      //最大可排课次数
      max_plan_num: "",
      //选中课程ID
      courseId: "",
      //级别课程ID
      levelId: "",
      //课次ID
      lessonId: "",
      //数据
      item: {},
      levelList: [],
      lessonList: []
    };
  },
  created() {
    this.item = this.$route.query.item;
    if (!this.isEmpty(this.item.class_id)) {
      localStorage.setItem("paikeitem", JSON.stringify(this.item));
      console.log(this.item);
    }
    if (this.item.plan_type == 2) {
      this.radio = "2";
    }
  },
  mounted() {
    if (this.isEmpty(this.item.class_id)) {
      this.item = JSON.parse(localStorage.getItem("paikeitem"));
      console.log(this.item);
    }
    this.httpData();
  },
  methods: {
    //课程改变的值
    changeCourse1(val) {
      this.levelList = this.item.subList[val].levelList;
      this.max_plan_num = "";
      this.levelId = "";
      this.lessonId = "";
      console.log(this.levelList);
    },
    //选中级别的回调
    changeCourse2(val) {
      console.log(val);
      this.lessonList = this.levelList[val].lessonList;
      this.max_plan_num = this.levelList[val].maxPlanNum;
      console.log(this.levelId);
      this.lessonId = "";
    },
    //获取班级信息
    httpData() {
      let param = this.getHttpParams();
      param.type = 5;
      param.cid = this.item.class_id;

      this.$http
        .fetchPost(this.$api.CLASSMANAGER, param)
        .then(res => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取列表数据
            this.item = resulte.data;
            this.isHttp = true;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch(err => {
          this.showLoading = 1;
          console.log("请求数据异常" + err);
        });
    }
  },
  computed: {},
  components: { SmartManageCourse, CalendarManageCourse }
};
</script>
<style lang="scss" scoped>
.course_away_record {
  height: calc(100vh - 177px);
  margin: 0 14px;
  .action {
    ::v-deep .el-tabs--top {
      height: 50px;
    }
  }
  ::v-deep .el-tabs__active-bar {
    background-color: transparent !important;
    line-height: 10px;
  }
  ::v-deep.el-tabs__nav-wrap::after {
    position: static !important;
  }
}
</style>
